<template>
  <div>
    <!-- 顶部搜索 -->
    <div class="searchBar">
      <div class="container inner">
        <div class="search">
          <el-select class="leftSelect" v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
          <el-input
            v-model="input"
            placeholder="请输入你要搜索的内容"
          ></el-input>
        </div>
        <a-button
          type="primary"
          size="large"
          @click="(searchResultShow = true), goSearch1()"
        >
          搜索
        </a-button>
      </div>
      <div class="searchTypeWrap container">
        <el-checkbox-group v-model="searchType">
          <el-checkbox
            v-for="(item, index) in searchTypeOpt"
            :key="index"
            :label="item.value"
          >
            {{ item.label }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
    </div>

    <!-- 搜索目录 -->
    <div class="catalogWrap container" v-if="!searchResultShow">
      <div class="list">
        <div class="commonTitle">热点/推荐</div>
        <div v-for="(item, index) in keyList1" :key="index">
          <div class="listTittle">{{ item.tittle }}</div>
          <div class="keys">
            <span
              v-for="(childItem, childIdx) in item.child"
              :key="childIdx"
              :class="{ red: childItem.mark }"
            >
              {{ childItem.key }}
            </span>
          </div>
        </div>
      </div>
      <div class="list listMiddle">
        <div class="commonTitle">行业知识服务</div>
        <div v-for="(item, index) in keyList2" :key="index">
          <div class="listTittle">{{ item.tittle }}</div>
          <div class="keys">
            <span
              v-for="(childItem, childIdx) in item.child"
              :key="childIdx"
              :class="{ red: childItem.mark }"
              @click="
                (searchResultShow = true), goSearch2(childItem.categoryId)
              "
            >
              {{ childItem.key }}
            </span>
          </div>
        </div>
      </div>
      <div class="list">
        <div class="commonTitle">专题知识库</div>
        <div v-for="(item, index) in keyList3" :key="index">
          <div class="listTittle">{{ item.tittle }}</div>
          <div class="keys">
            <span
              v-for="(childItem, childIdx) in item.child"
              :key="childIdx"
              :class="{ red: childItem.mark }"
            >
              {{ childItem.key }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- 搜索结果 -->
    <div class="container searchResultList" v-else>
      <div class="back">
        <a-icon type="left" @click="openBackConfirm" />
        <span class="ml8" @click="openBackConfirm">返回</span>
      </div>
      <ul v-if="resultList.length != 0">
        <li v-for="(item, index) in resultList" :key="index" @click="goDetail(item)">
          <div class="leftCol">
            <div class="tittle">{{ item.tittle }}</div>
            <div class="row2">
              <div class="keys">
                <span
                  v-for="(itemChild, childIdx) in item.keys"
                  :key="childIdx"
                >
                  {{ itemChild }}
                </span>
              </div>
              <div class="time">发表时间： {{ item.time }}</div>
            </div>
            <div class="contents">
              {{ item.contents }}
            </div>
          </div>
          <div class="rightImg" v-if="item.img">
            <img :src="item.img" />
          </div>
        </li>
      </ul>
      <div class="noData" v-else>
        <div class="inner">
          <img
            src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/noData.png"
          />
          未查询到结果
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryProductCategory } from "@/api/ObtainEvidence";
import { queryProducts } from "@/api/ObtainEvidence";
export default {
  name: "Search",
  components: {},
  data() {
    return {
      searchResultShow: false,
      resultList: [],
      options: [
        {
          value: "-1",
          label: "全部",
        },
      ],
      value: "-1",
      input: "",
      searchType: [1],
      searchTypeOpt: [
        {
          value: 1,
          label: "电子书",
        },
        {
          value: 2,
          label: "文章",
        },
        {
          value: 3,
          label: "视频",
        },
        {
          value: 4,
          label: "音频",
        },
      ],
      keyList1: [
        {
          tittle: "热门话题",
          child: [
            {
              key: "研究生",
              mark: 1,
            },
            {
              key: "新型冠状病毒肺炎(OA)",
            },
            {
              key: "线上教育",
            },
            {
              key: "新城役",
            },
            {
              key: "内卷化",
            },
            {
              key: "三体",
            },
          ],
        },
        {
          tittle: "推荐",
          child: [
            {
              key: "人工智能原理与方法",
            },
            {
              key: "产业生态学",
            },
            {
              key: "疫情冲击下的2022年中国经济形势与政策选择",
            },
            {
              key: "物联网:概念、架构与关键技术研究综述",
            },
            {
              key: "浅谈当代青年的使命担当",
            },
            {
              key: "乡村治理",
            },
            {
              key: "人脸识别",
            },
            {
              key: "大学生心理健康",
            },
            {
              key: "垃圾分类",
            },
            {
              key: "新能源汽车",
            },
            {
              key: "数据库",
            },
            {
              key: "人类命运共同体",
            },
          ],
        },
      ],
      keyList2: [],
      // keyList2: [
      //   {
      //     tittle: '党政、教育、法律、金融',
      //     child: [
      //       {
      //         key: '人大'
      //       },
      //       {
      //         key: '政府'
      //       },
      //       {
      //         key: '思想'
      //       },
      //       {
      //         key: '高职'
      //       },
      //       {
      //         key: '职教'
      //       },
      //       {
      //         key: '基教'
      //       },
      //       {
      //         key: '法学'
      //       },
      //       {
      //         key: '律法'
      //       },
      //       {
      //         key: '调解'
      //       },
      //       {
      //         key: '财经'
      //       },
      //       {
      //         key: '数字经济'
      //       },
      //       {
      //         key: '金融科技'
      //       }
      //     ]
      //   },
      //   {
      //     tittle: '交通运输、电力、建筑',
      //     child: [
      //       {
      //         key: '交通'
      //       },
      //       {
      //         key: '船舶'
      //       },
      //       {
      //         key: '运输'
      //       },
      //       {
      //         key: '发电'
      //       },
      //       {
      //         key: '节能'
      //       },
      //       {
      //         key: '能量回收'
      //       },
      //       {
      //         key: '建筑材料'
      //       },
      //       {
      //         key: '城乡规划'
      //       },
      //       {
      //         key: '钢筋'
      //       },
      //       {
      //         key: '板材'
      //       }
      //     ]
      //   },
      //   {
      //     tittle: '科学研究、贸易、能源',
      //     child: [
      //       {
      //         key: '科普'
      //       },
      //       {
      //         key: '知识'
      //       },
      //       {
      //         key: '实验室'
      //       },
      //       {
      //         key: '研究'
      //       },
      //       {
      //         key: '创业创新'
      //       },
      //       {
      //         key: '交易'
      //       },
      //       {
      //         key: '商贸'
      //       },
      //       {
      //         key: '自然资源'
      //       },
      //       {
      //         key: '能量守恒'
      //       },
      //       {
      //         key: '新能源'
      //       },
      //       {
      //         key: '电池'
      //       }
      //     ]
      //   },
      //   {
      //     tittle: '农林、卫生、信息技术、艺术',
      //     child: [
      //       {
      //         key: '牧鱼'
      //       },
      //       {
      //         key: '食品'
      //       },
      //       {
      //         key: '医疗'
      //       },
      //       {
      //         key: '药业'
      //       },
      //       {
      //         key: '公共卫生'
      //       },
      //       {
      //         key: '信息通讯'
      //       },
      //       {
      //         key: '互联网'
      //       },
      //       {
      //         key: '硬件'
      //       },
      //       {
      //         key: '文艺'
      //       },
      //       {
      //         key: '表演'
      //       }
      //     ]
      //   }
      // ],
      keyList3: [
        {
          tittle: "农党政/红色专题",
          child: [
            {
              key: "党建",
            },
            {
              key: "政协",
            },
            {
              key: "党校",
            },
            {
              key: "社团",
            },
            {
              key: "国防",
            },
          ],
        },
        {
          tittle: "公共管理",
          child: [
            {
              key: "公共图书馆",
            },
            {
              key: "文博",
            },
            {
              key: "档案",
            },
            {
              key: "出版传媒",
            },
          ],
        },
        {
          tittle: "社会知识/环保治理/金融/文化",
          child: [
            {
              key: "文博",
            },
            {
              key: "旅游",
            },
            {
              key: "传统文化",
            },
            {
              key: "文艺文化",
            },
            {
              key: "非遗",
            },
          ],
        },
        {
          tittle: "金融",
          child: [
            {
              key: "会计",
            },
            {
              key: "产业",
            },
            {
              key: "银行",
            },
            {
              key: "基金",
            },
            {
              key: "保险",
            },
          ],
        },
      ],
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      productFilter: {
        categoryId: null,
        search: null,
        productType: null,
        goodsStatus: "1",
      },
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    goDetail(item) {
      console.log("DetailView..");
      this.$router.push({
        // path: "/OnSale/DetailView?goodsId="+item.goodsId,
        path: "/OnSale/Detail?goodsId="+item.goodsId,
        query: { fromWhere: "list" },
      });
    },

    fetchData() {
      queryProductCategory(0).then(({ data }) => {
        // console.log("行业知识服务列表:", data);
        let list = [];
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          let elementChildrenList = [];
          for (let n = 0; n < element.children.length; n++) {
            const elementChildren = element.children[n];
            elementChildrenList.push({
              key: elementChildren.name,
              categoryId: elementChildren.categoryId,
            });
          }
          list.push({
            tittle: element.name,
            child: elementChildrenList,
          });
        }
        // console.log(list);
        this.keyList2 = list;
      });
    },

    goSearch1(input) {
      this.productFilter.categoryId = null;
      this.productFilter.search = null;
      this.productFilter.productType = null;
      this.productFilter.search = this.input;
      queryProducts(
        this.pagination.page,
        this.pagination.pageSize,
        this.productFilter
      ).then(({ data }) => {
         this.renderList(data);
      });
    },
    goSearch2(categoryId) {
      this.productFilter.categoryId = null;
      this.productFilter.search = null;
      this.productFilter.productType = null;
      // console.log("categoryId:" + categoryId);
      this.productFilter.categoryId = categoryId;
      queryProducts(
        this.pagination.page,
        this.pagination.pageSize,
        this.productFilter
      ).then(({ data }) => {
        this.renderList(data);
      });
    },
    renderList(data){
  // data = JSON.stringify(data.list)
        console.log("查询列表：" , data);
        // {
        //   tittle: '繁星无法超越——三体问题溯源',
        //   keys: ["三体问题", "庞加莱"],
        //   contents: '三体问题是一个困扰学术界多年的难题.自牛顿在《自然哲学的数学原理》中首次定义三体问题开始,人们已经在这个问题上努力了300年之久.欧拉和拉格朗日先后研究了一些较为简单的三体系统问题,而庞加莱首先考虑了限制性的三体问题,但发现其轨迹十分复杂而无法描绘.他转而证明了三体问题不存在解析，并着手创立了一…',
        //   time: '2020-12-28 18:00'
        // },
        let list = [];
        for (let i = 0; i < data.list.length; i++) {
          list.push({
            tittle: data.list[i].productName,
            keys: [data.list[i].goods.categoryName],
            goodsId: data.list[i].goods.goodsId,
            contents: data.list[i].description,
            img: "",
            time:  this.dayjs(data.list[i].createTime * 1000).format("YYYY-MM-DD HH:mm:ss "),
          });
        }
        this.resultList = list;
    },
    // 返回列表
    openBackConfirm() {
      this.searchResultShow = !this.searchResultShow;
    },
  },
};
</script>

<style lang='scss' scoped>
.searchBar {
  background: url("https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/ipChain-topBanner.png")
    no-repeat 50% 100%;
  background-size: 100% 100%;
  padding: 72px 0;
  /deep/ .el-input.is-active .el-input__inner,
  /deep/ .el-input__inner:focus {
    box-shadow: none;
    border: none;
  }
  .inner {
    display: flex;
    .ant-btn {
      margin-left: 8px;
      height: 48px;
      border-radius: 2px;
      padding: 0 21px;
    }
  }
  .search {
    height: 48px;
    line-height: 48px;
    background: #fff;
    border-radius: 2px;
    flex: 1;
    display: flex;
    /deep/ .el-select .el-input.is-focus .el-input__inner,
    /deep/ .el-input__inner {
      border: none;
    }
    .leftSelect /deep/ .el-input__inner {
      text-align: center;
      color: rgba(0, 18, 22, 0.45);
    }
  }
  .searchTypeWrap {
    margin-top: 16px;
  }
  .searchTypeWrap,
  .inner {
    padding: 0 8%;
  }
  /deep/ .el-checkbox {
    color: #fff;
    font-weight: normal;
  }
}

.catalogWrap {
  margin-top: -40px;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  .list {
    background: #fff;
    box-shadow: 0 0 16px rgba(0, 109, 117, 0.06);
    padding: 16px 16px 24px;
    color: rgba(0, 18, 22, 0.85);
    border-radius: 8px;
    flex: 0 0 30%;
  }
  .listMiddle {
    flex: 1;
    margin: 0 16px;
  }
  .commonTitle {
    color: #007dff;
    font-size: 18px;
  }
  .commonTitle::before {
    height: 20px;
  }
  .listTittle {
    font-weight: bold;
    margin: 24px 0 0;
  }
  .keys {
    span {
      font-size: 12px;
      color: rgba(0, 18, 22, 0.5);
      display: inline-block;
      margin-right: 12px;
      margin-top: 8px;
      cursor: pointer;
    }
    .red {
      color: #f5706d;
    }
  }
}
.searchResultList {
  margin-top: -40px;
  margin-bottom: 32px;
  background: #fff;
  box-shadow: 0 0 16px rgba(0, 109, 117, 0.06);
  padding: 48px 56px;
  color: rgba(0, 18, 22, 0.85);
  border-radius: 8px;
  .back {
    margin: 16px 0;
    display: flex;
    align-items: center;
    & > * {
      cursor: pointer;
    }
  }
  li {
    border-bottom: solid 1px rgba(0, 109, 117, 0.06);
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    .leftCol {
      flex: 1;
    }
    .rightImg {
      flex: 0 0 90px;
      margin-left: 16px;
      padding-top: 24px;
      img {
        width: 100%;
      }
    }
    .tittle {
      font-size: 20px;
      font-weight: bold;
      margin: 16px 0 10px;
    }
    .row2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .keys {
        span {
          display: inline-block;
          background: #f7f9fa;
          padding: 2px 4px;
          border: solid 1px #eeeeee;
          color: rgba(0, 18, 22, 0.5);
          font-size: 12px;
          margin-right: 16px;
        }
      }
      .time {
        color: rgba(0, 18, 22, 0.65);
      }
    }
    .contents {
      color: rgba(0, 18, 22, 0.65);
      margin: 12px 0 16px;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
@media (max-width: 576px) {
  .catalogWrap {
    flex-wrap: wrap;
    .list {
      flex: 100%;
    }
    .listMiddle {
      flex: 100%;
      margin: 16px 0;
    }
  }
  .searchResultList {
    padding: 12px 24px 24px;
    li {
      align-items: center;
      .rightImg {
        flex: 0 0 80px;
        margin-left: 8px;
      }
      .row2 {
        flex-wrap: wrap;
        .keys {
          order: 2;
          flex: 100%;
        }
        .time {
          order: 1;
          flex: 100%;
        }
      }
    }
  }
}
</style>
